import React from "react";
import { useState } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
 
import { MDBDataTable } from "mdbreact";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";

import { SWIPELINC_API  } from "../../utils/constant";
import swal from "sweetalert";
import TablePopUp from "./TablePopUp";
import {
  getAllPayoutRequest,
  payoutRequestDelete,
} from "../../services/apiService";
import BalanceComponent from "./Balance";
import CheckExportConst from "./UserManagement/CheckexportConst";

function RegisterPayout({ setShowLoader }) {
  
  const [apiResponse, setApiResponse] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [getAgain, setGetAgain] = useState(false);

  

  const [openPopUp, setOpenPopUp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  const handleCheckboxChange = (rowData, checked) => {
    if (checked) {
      // Add the row data to selectedRows
      setSelectedRows((prevRows) => [...prevRows, rowData]);
    } else {
      // Remove the row data from selectedRows
      setSelectedRows((prevRows) =>
        prevRows.filter((row) => row.id !== rowData.id)
      );
    }
  };

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const [getReq, setGetReq] = useState(false);

  const handleAddButton = () => {
    setOpenPopUp(!openPopUp);
  };

  const column = [
    {
      label: "Select",
      field: "checkbox",
      sort: "disabled",
    },

    {
      label: "Vendor Name",
      field: "vendorName",
      sort: "disabled",
    },
    // {
    //   label: "ID",
    //   field: "id",
    //   sort: "disabled",
    // },
    {
      label: "Type",
      field: "payoutFor",
      sort: "disabled",
    },
    {
      label: "Category",
      field: "category",
      sort: "disabled",
    },

    {
      label: "Mobile",
      field: "mobile",
      sort: "asc",
      width: "auto",
    },
    {
      label: " e-Mail ID",
      field: "email",
      sort: "asc",
      width: "850px",
    },
    {
      label: "UTR Number",
      field: "rrn",
      sort: "asc",
      width: "850px",
    },

    {
      label: "VPA / UPI ID",
      field: "userVpa",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Account Number",
      field: "accountNumber",
      sort: "asc",
      width: "auto",
    },
    {
      label: "IFSC Code",
      field: "ifscCode",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Amount In Rupees",
      field: "amount",
      sort: "disabled",
      width: "400px",
    },
    {
      label: "Action",
      field: "delete",
      sort: "disabled",
      width: "400px",
    },
  ];
  
  const rows = apiResponse.map((entry) => ({
    checkbox: (
      <input
        type="checkbox"
        className="align-self-center"
        checked={selectedRows.some((row) => row.id === entry.id)}
        onChange={(e) => handleCheckboxChange(entry, e.target.checked)}
      />
    ),
    accountNumber: entry.accountNumber || "",
     
    id: entry.id || "",
    amount: entry.amount || "",
    bankName: entry.bankName || "",
    branchName: entry.branchName || "",
    category: entry.category || "",
    email: entry.email || "",
    vendorName: entry.fullName || "",
    ifscCode: entry.ifscCode || "",
    mobile: entry.mobileNo || "",
    userVpa: entry.upi || "",
    payoutFor: entry.payoutFor.toUpperCase() || "",
    delete: (
      <div className="d-flex flex-column align-items-center text-danger rounded-5">
        <i
          class="bi bi-trash3-fill"
          onClick={() => handleDelete(entry.id, token)}
        ></i>
      </div>
    ),
  }));

  const data2 = {
    columns: column,
    rows: rows,
  };

  const getRequest = async () => {
    setShowLoader(true)
    await getAllPayoutRequest(mid, token)
      .then((response) => {
        if (response.statusCode === 200) {
          setApiResponse(response.data);
          setShowLoader(false)
          // console.log(apiResponse);
        } else if (response.statusCode === 304) {
          setApiResponse([]);
          setShowLoader(false)
        } else {
          console.error("API Call Failed");
          setShowLoader(false)
          // Handle failure
        }
      })
      .catch((error) => {
        console.log(error.message);
        // setErrorText("Something Went Wrong");
        //  setErrorText("No Data Found")
      });
  };

  const RequestDelete = async (id) => {
    setShowLoader(true)
    await payoutRequestDelete(id, token)
      .then((response) => {
        if (response.statusCode === 200) {
          // console.log("deleted");
          setShowLoader(false)
        } else {
          console.error("API Call Failed");
          setShowLoader(false)
          // Handle failure
        }
      })
      .catch((error) => {
        console.log(error.message);
        // setErrorText("Something Went Wrong");
        //  setErrorText("No Data Found")
      });
  };

  const handleDelete = async (id, token) => {
    // Show a confirmation dialog
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this request!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        // User confirmed, delete the request
        setShowLoader(true)
        try {
          await RequestDelete(id, token);
          setShowLoader(false)
          swal("Request has been deleted!", {
            icon: "success",
          });
          setGetReq(!getReq);
        } catch (error) {
          console.error("Error:", error.message);
          // Handle error
          swal("Error occurred while deleting the request!", {
            icon: "error",
          });
        }
      } else {
        // User canceled the delete action
        swal("Request deletion canceled!");
      }
    });
  };
  useEffect(() => {
    getRequest(mid, token);
   setGrandTotal('')
   setSelectedRows([])
  }, [getReq, openPopUp]);
 
useEffect(() => {
  let totalAmount = selectedRows.reduce(
    (acc, row) => acc + parseFloat(row.amount || 0),
    0
  );
  setGrandTotal(totalAmount.toFixed(2));

}, [selectedRows,getReq, openPopUp])


const handleDataSubmission = async () => {

  if(selectedRows.length === 0) {
    swal({
      title: "Alert",
      text: "Please tick check box the perform action!!",
      icon: "error",
    });
    return
  }

  // Calculate the grand total of selected rows' amounts
  if (isSubmitting || selectedRows.length === 0) {
    // Prevent multiple submissions while one is in progress
    return;
  }

  setIsSubmitting(true)
  // Prepare the data to be submitted
  const DataForSubmit = selectedRows.map((data) => {
    return data;
  });

  console.log(DataForSubmit)
  const convertToPayloadFormat = (DataForSubmit) => {
  
    console.log("not returned")
        return DataForSubmit.map((item) => {
          return {
            accountNumber: item.accountNumber || "",
            amount: item.amount || "",
            bankName: item.bankName || "",
            branchName: item.branchName || "",
            category: item.category || "",
            email: item.email || "",
            fullName: item.fullName || "",
            ifscCode: item.ifscCode || "",
            mid: mid,
            mobile: item.mobileNo || "",
            payoutFor: item.payoutFor || "",
            remark: item.remark || "",
            requestId: item.id || 0,
            upi: item.upi || "",
          };
        });
      };
      const payload = convertToPayloadFormat(DataForSubmit);
      console.log(payload);

if(payload.length){
  try {
    const response = await axios.post(
      `${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/save`,
      payload,
      {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      }
    );

    if(response.data.statusCode === 309) {
      swal({
        title: "Alert",
        text: response.data.message,
        icon: "error",
      });
      
      return 
    }

    if (response.data.statusCode === 200) {
      swal({
        title: "Success",
        text: "Your Payout request has been submitted successfully.",
        icon: "success",
      });
      setGetReq(!getReq)
      // clearTable();
      console.log("Submitted Successfully");
    } else if(response.data.statusCode === 304){
      swal({
        title: "Failed",
        text: "Your Payout request is initiated but declined by bank.",
        icon: "warning",
      });
    }else if(response.data.statusCode === 600){
      swal({
        title: "Failed",
        text: "Something Went Wrong.",
        icon: "error",
      });
    }
  } catch (error) {
    swal({
      title: "Failed",
      text: "Something Went Wrong.",
      icon: "error",
    });
  }finally {
    setIsSubmitting(false); // Reset the submission status to false
  }
   
}

  // // Make an API call to submit the selected data
  
};

const {
  PayoutReqPrivilages , hasPrivilagesPTVendor,hasPrivilagesPTCustomer, hasPrivilagesViewPayoutReq , hasPrivilagesPayoutTxnStatus,
} = CheckExportConst();
  
  return (
    <>
    {/* {!isSubmitting?<MyLoader/>:''} */}
      <div className="ds-header-sec border border-5">
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 mb-4 ds-header-card">
              <Card.Body>
                <Col>
                  <div className="d-flex justify-content-between">
                  {hasPrivilagesPTCustomer || hasPrivilagesPTVendor ?  <Button
                      className="text-capitlize bg-black rounded-5 border-0 "
                      onClick={handleAddButton}
                    >
                      {" "}
                      <i className="bi bi-plus-square fs-5 mx-2"></i> Add Customer /
                      vendor
                    </Button> : null}
                    
                    <BalanceComponent isShowWalletBalance="false" isShowIMPSOrUPI="true"  getReq={getReq}/>
                  </div>
                </Col>
                {openPopUp ? (
                  <Col>
                    <TablePopUp
                      setShowLoader={setShowLoader} 
                      setOpenPopUp={setOpenPopUp}
                      setApiResponse={setApiResponse}
                      apiResponse={apiResponse}
                    />
                  </Col>
                ) : (
                  ""
                )}
              </Card.Body>
            </Card>
            {/* ./card */}
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 mb-4 ds-header-card">
              <Card.Body>
                <Col>
                  <MDBDataTable
                    className=" dataTable overflow-x-scroll"
                    data={data2}
                    footer="none"
                    striped
                    bordered
                    entries={50}
                    exportToCSV
                    responsive
                    theadColor="#333"
                    // small
                  />
                </Col>
                <Col className="d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-3">
                  {hasPrivilagesPTCustomer || hasPrivilagesPTVendor ?  <Button
                      className="text-capitlize mt-2"
                      size="sm"
                      variant="primary"
                      onClick={handleAddButton}
                    >
                      <i className="bi bi-plus-square fs-5 mx-2"></i> Add More
                    </Button> : null }
                    
                  </div>
                  <div className="d-flex   flex-column flex-end h-25">
                    <div className="d-flex align-items-center justify-content-center mt-2 d-flex ">
                      <label className="w-fit-content fs-5 text-uppercase ">
                        {" "}
                        Grand Total -
                      </label>
                      <div className="border border-2 border-dark rounded-2 px-3 pl-3  mx-3 h-25">
                        {grandTotal}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end ">
                      {/* {if(errorText==="")} */}
                      <Button
                        className={`text-capitlize ml-2 mt-2 mx-5 fs-4 w-auto`}
                        size="sm"
                        variant="primary"
                        onClick={handleDataSubmission}
                        disabled={isSubmitting}
                      >
                         {!isSubmitting?"Submit":"Please Wait..."}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Card.Body>
              {/* <SubmissionPopup show={showPopup} onClose={handlePopupClose} /> */}
            </Card>
            {/* ./card */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default RegisterPayout;