import React, { useState } from "react";
import InactiveServices from "../../common/InactiveServices";
import { Card, Col, Container, Row } from "react-bootstrap";
import VirtualTerminal from "./VirtualTerminal";
import MyLoader from "../../common/MyLoader";
import LinkBasedPayment from "./LinkBasedPayment";
import StaticQR from "./StaticQR";
import CheckExportConst from "../Tools/UserManagement/CheckexportConst";

const Payment = () => {
  const [activeButton, setactiveButton] = useState();
  const [showLoader, setShowLoader] = useState(false);

  const {
    hasPrivilagesTandP,
    hasPrivilagesLBP,
    hasPrivilagesQR,
    hasPrivilagesVT,
  } = CheckExportConst();

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec merchant-settings-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {hasPrivilagesQR ? (
                          <li
                            className={activeButton === 1 ? "active" : ""}
                            onClick={() => setactiveButton(1)}
                          >
                            QR
                          </li>
                        ) : null}

                        {hasPrivilagesVT ? (
                          <li
                            className={activeButton === 2 ? "active" : ""}
                            onClick={() => setactiveButton(2)}
                          >
                            Virtual Terminal
                          </li>
                        ) : null}
                        {hasPrivilagesTandP ? (
                          <li
                            className={activeButton === 4 ? "active" : ""}
                            onClick={() => setactiveButton(4)}
                          >
                            Tap N Pay
                          </li>
                        ) : null}
                        {hasPrivilagesLBP ? (
                          <li
                            className={activeButton === 3 ? "active" : ""}
                            onClick={() => setactiveButton(3)}
                          >
                            Link Based Payments
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>

              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4">
                  {activeButton === 2 ? (
                    <VirtualTerminal setShowLoader={setShowLoader} />
                  ) : activeButton === 1 ? (
                    <StaticQR setShowLoader={setShowLoader} />
                  ) : activeButton === 3 ? (
                    <LinkBasedPayment setShowLoader={setShowLoader} />
                  ) : (
                    <InactiveServices />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Payment;
