import React from "react";
import { useIdleTimer } from "react-idle-timer";

import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const IdealTimerContainer = () => {
  const navigate = useNavigate();

  const handleOnIdle = (event) => {
    swal({
      title: "Alert!",
      text: " Session Expired ! Please Log In Again",
      icon: "warning",
    }).then(() => {
      localStorage.removeItem("token");
      //   Cookies.remove("access_token");
      navigate("/");
    });
  };

  const handleOnActive = (event) => {
    // console.log('User is active', event);
    // console.log('Time remaining', getRemainingTime());
  };

  const handleOnAction = (event) => {
    // console.log('User did something', event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 10, // 10 minutes

    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return <>{/* <h1>Hello Idle</h1> */}</>;
};

export default IdealTimerContainer;
