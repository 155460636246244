import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  EnableDisableAggragator,
  GET_ALL_USER,
  SWIPELINC_API,
  USER_ENABLE_DISABLE,
} from "../../../utils/constant";
// import ViewSingleSubmittedAggregator from "./ViewSingleSubmittedAggregator";
import swal from "sweetalert";
// import EditSingleSubmittedAggregator from "./EditSingleSubmittedAggregator";
import axios from "axios";
// import { getAllFunctionForApiCalls } from "../../../services/apiService";
import EditSubmittedUser from "./EditSubmittedUser";
import ViewSubmittedUser from "./ViewSubmittedUser";
import { getAllFunctionForApiCalls } from "../../../services/apiService";

const SubmittedUser = ({ setShowLoader }) => {
  const [submittedAggregator, setSubmittedAggregator] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [callAgain, setCallAgain] = useState(false);
  const [EnableOrDisable, setEnableOrDisable] = useState("");
  const [openPrivilagesView, setOpenPrivilagesView] = useState(false);
  const [id, setId] = useState("");
  const [data, setData] = useState({});
  const tokenproxy = localStorage.getItem("token");
  const token = tokenproxy.slice(1,-1);

  const View = (data) => {
    console.log(data.merchantUserId)
    setId(data.merchantUserId);
    setData(data);
    setOpenView(true);
  };

  const handleClose = () => {
    setId("");
    setOpenView(false);
  };

  const handleCloseEdit = () => {
    setId("");
    setOpenPrivilagesView(false);
  };

  const EditView = (data) => {
    setId(data.merchantUserId);
    setData(data);
    setOpenPrivilagesView(true);
  };

  const columns = [
    { label: "Merchant User Name", field: "fullName", width: "auto" },
    
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Enable / Disable", field: "active", width: "auto" },

    //   { label: "Privilages", field: "PrivilagesView", width: "150px" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData = submittedAggregator
    ? submittedAggregator.map((item) => {
        const mappedItem = { ...item };
        mappedItem.action = (
          <div>
            <button
              className="btn btn-primary mx-2"
              onClick={() => View({...item})}
            >
              View Details
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => EditView({...item})}
            >
              Edit
            </button>
          </div>
        );
        mappedItem.active = (
          <div>
            <button
              className={`btn ${!item.active ? "btn-primary" : "btn-danger"}`}
              value={item.active ? "disable" : "enable"}
              onClick={(e) => disableAggregator(e, item.merchantUserId )}
            >
              {item.active ? "Disable" : "Enable"}
            </button>
          </div>
        );

        return mappedItem;
      })
    : [];

  const getAll = async (GET_ALL_USER, token) => {
    try {
      const response = await getAllFunctionForApiCalls(
        GET_ALL_USER ,
        token
      ); // Add the await keyword here

      if (response.statusCode === 200) {
        setSubmittedAggregator(response?.data);
      } else {
        setSubmittedAggregator([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAll(GET_ALL_USER, token);
  }, [callAgain,openPrivilagesView]);

  const disableAggregator = async (e, id) => {
     const EnableOrDisable=e.target.value

    swal({
      title: "Alert",
      text: "Are you sure, you want to disable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        setShowLoader(true);

        axios
          .put(
            `${USER_ENABLE_DISABLE}${EnableOrDisable}`, // Fix the template string
            formData,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if(res.data.statusCode===200){
              swal({
                title: "Success",
                text: `User ${EnableOrDisable} Successfully.`,
                icon: "success",
              })
              setCallAgain(prevState => !prevState);
            }else{
              swal({
                title: "Success",
                text: `Failed to ${EnableOrDisable} User.`,
                icon: "error",
              })
            }
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      } else {
        // User clicked cancel in the swal confirmation
        setShowLoader(false);
      }
    });
  };

  return (
    <Row>
      <Col xs={12} lg={12} md={12}>
        {/* <Card className="shadow border-0 mb-4">
              <Card.Body> */}
        <div className="report-grid-view">
          <div className="material-table-records mb-3">
            {!openView && !openPrivilagesView ? (
              <MDBDataTable
                className="dataTable"
                striped
                small
                bordered
                hover
                responsive
                data={{
                  columns: columns,
                  rows: mappedData,
                }}
                searching={true}
                entries={50}
                exportToCSV
                theadColor="#333"
                paginationLabel={["Previous", "Next"]}
                sortable={true}
                footer="none"
              />
            ) : openPrivilagesView ? (
              <EditSubmittedUser
                handleClose={handleCloseEdit}
                data={data}
                userId={id}
                setShowLoader={setShowLoader}
              />
             
            ) : openView ? (
              <ViewSubmittedUser
                handleClose={handleClose}
                data={data}
                userId={id}
              />
               
            ) : null}
          </div>
        </div>
        {/* </Card.Body>
            </Card> */}
      </Col>
    </Row>
  );
};

export default SubmittedUser;
