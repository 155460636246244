import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import axios from "axios";
import styles from "./AdharForm.module.css";
// import logo from "../../assets/images/Rectangle 13.png";
import {
  // updateCompanyNameAf,
  // updatePincodeAf,
  // updateStateAf,
  // updateCityAf,
  updateAuthorizedPersonNameAf,
  updateAadharNumberAf,
  updatePanCardNumberAf,
  // updateDistrictAf,
  updateAadharNumberVerifyAf,
  updatePanCardNumberVerifyAf,
  updateFirstName,
  updateLastName,
  updateBusinessType,
  updateBusinessTypeId,
  updateCompanyName,
  updateCompanyPanNumber,
  updateBusinessAs,
  updateWebsite,
  updateBusinessCategory,
  // updateBusinessCategoryId,
  updateGstNumber,
  updateOtherBusinessCategory,
  updateGstVerified,
  updateCompanyPanNumberVerified,
  updateRegistrationTypeId,
  updateAppurl,
  updatePanCardHolderName,
  updatePanCardHolderDOB,
  updateCompanyPanCardHolderName,
  updateCompanyPanCardHolderDOB,
} from "../../store/slices/onBoardSlice";
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from "./ResponsiveAppBar";
import "../../stylesheet/MerchantForm.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { SWIPELINC_API } from "../../utils/constant";
import {
  getKeyPressed,
  isGSTRegexIsValid,
  isPanNumRegesIsValid,
  isPanNumRegexIsValid,
  isWebsiteURLRegexIsValid,
  stopNumberCharInput,
  stopSpecialCharacterInput,
  useFetchPressedKey,
} from "../../utils/validations";
import swal from "sweetalert";
import MyLoader from "../../common/MyLoader";

const AdharForm = () => {
  const prevData = useSelector((state) => state.onBoard);
  const [showComPanInput, setShowComPanInput] = useState(
    prevData.businessType === "6" ? false : true
  );
  const [showIncorrectOTP, setShowIncorrectOTP] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupPan, setShowPopupPan] = useState(false);
  const [adharVerify, setAdharVerify] = useState();
  const [adharVerifyValue, setadharVerifyValue] = useState(false);
  const [adharVerifyOtp, setAdharVerifyOtp] = useState();
  const [showPopupAdhar, setShowPopupAdhar] = useState();
  const [panVerify, setPanVerify] = useState();
  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [panVerifyValueAf, setPanVerifyValueAf] = useState(false);
  const [isPersonalPanVerified, setIsPersonalPanVerified] = useState(false);
  const [otpResendAttempts, setOtpResendAttempts] = useState(0);
  const [showSkipButton, setShowSkipButton] = useState(false);
  const [showResendOTP, setShowResendOTP] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [sendOTPCount, setSendOTPCount] = useState(0);
  const [action, setAction] = useState();
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiMobileResponse, setApiMobileResponse] = useState(null);
  const [registerId, setRegisterId] = useState();
  const [otpCorrect, setOtpCorrect] = useState(false);
  const [mobileOtpCorrect, setMobileOtpCorrect] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [registrationType, setRegistrationTypes] = useState([]);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessType, setBusinessTypes] = useState([]);
  const [isGstVerify, setisGstVerify] = useState(false);
  const [authorizedPersonName, setAuthorizedPersonName] = useState("");
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [renderCompanyPan, setRenderCompanyPan] = useState("");

  const [isOtpTimerStart, setIsOtpTimerStart] = useState(false);
  const [otpTimer, setOtpTimer] = useState(45);

  const dispatch = useDispatch(); // Get the dispatch function
  const navigate = useNavigate();

  if (!localStorage.getItem("registrationId")) {
    navigate("/EmailForm");
  }

  const initialState = {
    registrationTypeId: "",
    businessType: "",
    businessCategory: "",
    otherBusinessCategory: "",
    companyName: "",
    gstNumber: "",
    companyPanNumber: "",
    businessAs: "",
    website: "",
    appUrl: "",
    firstName: "",
    lastName: "",
    otp: "",
    panCardNumber: "",
    aadharNumber: "",
    // typeOfServices: "",
    isAadharNumberVerified: false,
  };

  useEffect(() => {
    if (isOtpTimerStart) {
      let myInterval = setInterval(() => {
        if (otpTimer > 0) {
          setOtpTimer(otpTimer - 1);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [otpTimer, isOtpTimerStart]);

  // startOtpTimer()

  const getInitialValues = (prevData) => {
    const isReturning = localStorage.getItem("isReturning");
    // console.log(prevData.aadharNumberVerifyAf);
    // console.log(prevData.panCardNumberAf);
    if (isReturning) {
      // setIsAadharVerified(prevData.aadharNumberVerifyAf)
console.log(prevData)
      return {
        ...initialState,
        registrationTypeId: prevData.registrationTypeId,
        businessType: prevData.businessType,
        businessCategory: prevData.businessCategory,
        otherBusinessCategory: prevData.otherBusinessCategory,
        companyName: prevData.companyName,
        gstNumber: prevData.gstNumber,
        companyPanNumber: prevData.companyPanNumber,
        businessAs: prevData.businessAs,
        website: prevData.website,
        appUrl: prevData.appUrl,
        firstName: prevData.firstName,
        lastName: prevData.lastName,
        aadharNumber: prevData.aadharNumberAf,
        isAadharNumberVerified:
          prevData.aadharNumberVerifyAf === "" ? false : true,
        panCardNumber: prevData.panCardNumberAf,
        panCardHolderName: prevData.panCardHolderName,
        panCardHolderDOB: prevData.panCardHolderDOB,
        // CompanyPanCardHolderDOB: prevData.companyPanCardHolderName,
        CompanyPanCardHolderDOB: prevData.companyPanCardHolderDOB,
        CompanyPanCardHolderName: prevData.companyPanCardHolderName,
        otp: "",
      };
    } else {
      return initialState;
    }
  };

  const [formData, setFormData] = useState(getInitialValues(prevData));

  useEffect(() => {
    console.log(getInitialValues(prevData));
    console.log(formData);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (stopSpecialCharacterInput(pressedKeyName) && name === "businessAs") {
      return;
    }

    // if (
    //   stopNumberCharInput(pressedKeyName) &&
    //   (name=== "companyName" )
    // ) {
    //   return;
    // }

    if (
      (stopSpecialCharacterInput(pressedKeyName) ||
        stopNumberCharInput(pressedKeyName)) &&
      name === "firstName"
    ) {
      return;
    }

    if (
      (stopSpecialCharacterInput(pressedKeyName) ||
        stopNumberCharInput(pressedKeyName)) &&
      name === "lastName"
    ) {
      return;
    }

    if (pressedKeyName === "Backspace" && name === "aadharNumber") {
      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }

    if (formData.aadharNumber.length >= 12 && name === "aadharNumber") {
      return;
    }

    if (pressedKeyName === "Backspace" && name === "otp") {
      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }

    if (formData.otp.length >= 6 && name === "otp") {
      return;
    }

    setFormData({ ...formData, [name]: value?.toUpperCase() });
    // let res;
    // if(name === "businessType") {
    //   // alert(value)
    //   res = businessType.filter((elem) => elem.companyTypeId === 5)
    // }
    // setRenderCompanyPan(res)
  };

  const handelValidations = (e) => {
    if (e.target.name === "companyPanNumber" && e.target.value !== "") {
      if (!isPanNumRegexIsValid(formData.companyPanNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Company PAN Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, companyPanNumber: "" });
        });
        return;
      }
    }

    if (e.target.name === "panCardNumber" && e.target.value !== "") {
      if (!isPanNumRegexIsValid(formData.panCardNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid PAN Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, panCardNumber: "" });
        });
        return;
      }
    }
  };

  const handlePanVerification = async (panBelongsTo) => {
    let payload;
    if (panBelongsTo === "company") {
      payload = {
        panNumber: formData.companyPanNumber,
        name: formData.CompanyPanCardHolderName,
        dob: formData.CompanyPanCardHolderDOB,
      };
    }
    if (panBelongsTo === "personal") {
      payload = {
        panNumber: formData.panCardNumber,
        name: formData.panCardHolderName,
        dob: formData.panCardHolderDOB,
      };
    }
    try {
      setShowLoader(true);
      const response = await axios.post(
        `${SWIPELINC_API}MerchantPanel/merchant/ekyc/${
          panBelongsTo === "company"
            ? "companyPanVerification"
            : "panVerification"
        }`,
        payload
      );
      const data = response.data;

      if (data.message === "Verified" && data.status === "Success") {
        setPanVerify(data);
        setShowLoader(false);
        if (panBelongsTo === "company") {
          setPanVerifyValueAf(true);
          // document.getElementById("companyPanName").innerText = data.data.Name;
          document.getElementById("companyPanVerifyBtn").innerText = "Verified";
          document.getElementById("companyPanVerifyBtn").disabled = "true";
        }
        setAuthorizedPersonName(formData.firstName + " " + formData.lastName);
        if (panBelongsTo === "personal") {
          setIsPersonalPanVerified(true);
          // document.getElementById("prsnlPanName").innerText = data.data.Name;
          document.getElementById("prsnlPanVerifyBtn").innerText = "Verified";
          document.getElementById("prsnlPanVerifyBtn").disabled = "true";
        }
      }
      if (data.message !== "Verified" || data.status !== "Success") {
        setPanVerify("Invalid Pan Number");
        setShowLoader(false);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const fetchBusinessCategories = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantPanel/merchant/business/getAllBusinessType`
      );
      const data = response.data;

      setBusinessCategories(data.data);
    } catch (error) {
      console.log("Error fetching business categories:", error);
    }
  };

  const fetchRegistrationTypes = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantPanel/merchant/registration/getAllRegistrationType`
      );
      const data = response.data;

      setRegistrationTypes(data.data);
    } catch (error) {
      console.log("Error fetching business categories:", error);
    }
  };

  const handleOTPVerification = async () => {
    if (formData.otp.length === 6) {
      try {
        setShowLoader(true);
        const response = await axios.post(
          `${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarOtpVerification`,
          {
            otp: formData.otp,
            refNumber: adharVerifyOtp.data,
          }
        );
        if (
          response.data.message === "Aadhaar verfied" &&
          response.data.status === "Success"
        ) {
          setShowLoader(false);
          setAdharVerify(response.data);
          setIsAadharVerified(true);
          setFormData({ ...formData, isAadharNumberVerified: true });
          document.getElementById("aadharCustomerName").innerHTML =
            response.data.data.Name;
          setShowOtpInput(false);
          document.getElementById("adharOTPVerifyBtn").disabled = true;
          document.getElementById("adharSendOTPBtn").disabled = true;
        }

        console.log(response.data.message);

        if (
          response.data.message === "invalid otp" &&
          response.data.statusCode === 307
        ) {
          swal({
            title: "Alert!",
            text: "Invalid Aadhar OTP !",
            icon: "error",
          });
          setShowLoader(false);
        }

        if (otpResendAttempts >= 2) {
          setAdharVerify(false);
          return;
        }
        if (
          response.data.message !== "Aadhaar verfied" ||
          response.data.status !== "Success"
        ) {
          setAdharVerify("Otp is Invalid");
          setShowLoader(false);
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  };

  const handleSkipAadharVerification = () => {
    document.getElementById("adharSendOTPBtn").disabled = true;
    setAdharVerify(false);
    setShowOtpInput(false);
  };

  const handleBusinessCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    if (selectedCategory === "3") {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
    handleChange(e);
  };

  const fetchBusinessTypes = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantPanel/merchant/company/getAllCompanyType`
      );
      const data = response.data;
      setBusinessTypes(data.data);
    } catch (error) {
      console.log("Error fetching business types:", error);
    }
  };

  const sendOTP = async () => {
    if (formData.aadharNumber.length === 12) {
      setSendOTPCount(sendOTPCount + 1);
      setShowLoader(true);
      // document.getElementById("aadharVerifyErr").classList.add("d-none");
      try {
        const response = await axios.post(
          `${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarVerification`,
          {
            aadhaarNumber: formData.aadharNumber,
          }
        );
        if (
          response.data.statusCode === 200 &&
          response.data.message === "Otp Send successfully"
        ) {
          setOtpTimer(45);
          setIsOtpTimerStart(true);
          setShowLoader(false);
          document.getElementById("aadharCustomerName").innerText =
            "OTP Sent Successfully !!";
          setShowIncorrectOTP(false);
          setAdharVerifyOtp(response.data);
          setadharVerifyValue(true);
          setShowOtpInput(true);
          document.getElementById("adharSendOTPBtn").disabled = true;
          setIsOtpTimerStart(true);

          setTimeout(() => {
            document.getElementById("adharSendOTPBtn").disabled = false;
          }, otpTimer * 1000);
          setOtpResendAttempts((prevAttempts) => prevAttempts + 1);
          if (otpResendAttempts >= 2) {
            setShowSkipButton(true);
          }
          if (otpResendAttempts === 0) {
            setShowResendOTP(true);
          }
        } else {
          setShowLoader(false);
          swal({
            title: "Alert!",
            text: response.data.message + " !",
            icon: "error",
          });
        }
      } catch (error) {}
    } else {
      setShowIncorrectOTP(true);
    }
  };

  useEffect(() => {
    fetchBusinessTypes();
    fetchBusinessCategories();
    fetchRegistrationTypes();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.registrationTypeId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Your Business Registration Type*",
        icon: "error",
      });
      return;
    }

    if (formData.businessType === "") {
      swal({
        title: "Alert!",
        text: "Please Select Your Business Type*",
        icon: "error",
      });
      return;
    }

    if (formData.businessCategory === "") {
      swal({
        title: "Alert!",
        text: " Please Select Your Business Category*",
        icon: "error",
      });
      return;
    }

    if (
      formData.businessCategory === "3" &&
      formData.otherBusinessCategory.trim() === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please Fill Business Category*",
        icon: "error",
      });
      return;
    }

    if (formData.companyName.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Fill Company Name* ",
        icon: "error",
      });
      return;
    }

    if (formData.website.trim() !== "") {
      if (!isWebsiteURLRegexIsValid(formData.website)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Website URL*",
          icon: "error",
        });
        return;
      }
    }

    if (showComPanInput === true) {
      if (formData.companyPanNumber.trim() === "") {
        swal({
          title: "Alert!",
          text: "Please Fill Company PAN*",
          icon: "error",
        });
        return;
      }
      // if (!isPanNumRegexIsValid(formData.companyPanNumber)) {
      //   swal({
      //     title: "Alert!",
      //     text: "Please Enter Valid PAN Number !",
      //     icon: "error",
      //   });
      //   return
      // }
    }

    if (formData.firstName.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Enter First Name*",
        icon: "error",
      });
      return;
    }

    if (formData.lastName.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Last Name*",
        icon: "error",
      });
      return;
    }

    if (formData.aadharNumber.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Aadhar Number*",
        icon: "error",
      });
      return;
    }

    if (formData.panCardNumber.trim() === "") {
      swal({
        title: "Alert!",
        text: " Please Enter PAN Number* ",
        icon: "error",
      });
      return;
    }
    if (showComPanInput) {
      if (!isPanNumRegexIsValid(formData.companyPanNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Company PAN Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, companyPanNumber: "" });
        });
        return;
      }
    }

    if (!isPanNumRegexIsValid(formData.panCardNumber)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid PAN Number !",
        icon: "error",
      }).then(() => {
        setFormData({ ...formData, panCardNumber: "" });
      });
      return;
    }

    if (!formData.isAadharNumberVerified && sendOTPCount < 3) {
      // document.getElementById("aadharVerifyErr").classList.remove("d-none");
      swal({
        title: "Alert!",
        text: " Please Verify Your Aadhar* ",
        icon: "error",
      });
      // document.getElementById("saveDetailsBtn").disabled = true;
      return;
    }

    dispatch(updateFirstName(formData.firstName));
    dispatch(updateLastName(formData.lastName));
    dispatch(updateBusinessType(formData.businessType));
    dispatch(updateCompanyName(formData.companyName));
    dispatch(updateCompanyPanNumber(formData.companyPanNumber));
    dispatch(updateBusinessAs(formData.businessAs));
    dispatch(updateWebsite(formData.website.trim()));
    dispatch(updateBusinessCategory(formData.businessCategory));
    dispatch(updateGstNumber(formData.gstNumber));
    dispatch(updateBusinessTypeId(formData.companyTypeId));
    dispatch(updateOtherBusinessCategory(formData.otherBusinessCategory));
    dispatch(updateRegistrationTypeId(formData.registrationTypeId));
    dispatch(updateAppurl(formData.appUrl));
    dispatch(updateAadharNumberAf(formData.aadharNumber));
    dispatch(updatePanCardNumberAf(formData.panCardNumber));
    dispatch(updateGstVerified(isGstVerify));
    dispatch(updateCompanyPanNumberVerified(panVerifyValueAf));
    dispatch(updatePanCardNumberVerifyAf(isPersonalPanVerified));
    dispatch(updateAadharNumberVerifyAf(formData.isAadharNumberVerified));
    dispatch(updateAuthorizedPersonNameAf(authorizedPersonName));
    dispatch(updatePanCardHolderName(formData.panCardHolderName));
    dispatch(updatePanCardHolderDOB(formData.panCardHolderDOB));
    dispatch(updateCompanyPanCardHolderName(formData.CompanyPanCardHolderName));
    dispatch(updateCompanyPanCardHolderDOB(formData.CompanyPanCardHolderDOB));

    navigate("/CompanyAddForm");
    // localStorage.removeItem("isReturning")
    localStorage.setItem("isReturning", false);
  };

  const handleGstVerification = async () => {
    try {
      setShowLoader(true);
      const response = await axios.post(
        `${SWIPELINC_API}MerchantPanel/merchant/ekyc/gstVerification`,
        {
          gstNumber: formData.gstNumber,
        }
      );
      const data = response.data;
      console.log("sss", data);

      if (data.message === "Verified" && data.statusCode === 200) {
        setisGstVerify(true);
        setShowLoader(false);
        document.getElementById("gstHolderName").innerHTML =
          data.data.companyName;
        document.getElementById("gstVerifyBtn").disabled = true;
      }
      if (data.message !== "Verified" && data.status !== "Success") {
        // setPanVerify("Invalid Pan Number");
        swal({
          title: "Alert!",
          text: data.message + " !",
          icon: "error",
        });
        setShowLoader(false);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      {showLoader ? <MyLoader /> : ""}

      <ResponsiveAppBar />
      <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
        <Container>
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <Card.Title className="h3">Company Details</Card.Title>
                    <div className="merchant-form-info">
                      <form
                        onSubmit={handleSubmit}
                        className="form-section-form-group"
                      >
                        <Row>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="businessType"
                                className="form-label"
                              >
                                Registration Type
                                <sup className="text-danger">*</sup>
                              </label>
                              <select
                                id="merchantForm"
                                name="registrationTypeId"
                                value={formData.registrationTypeId}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option value="">
                                  Select a Registration type*
                                  {/* <span className="star">*</span> */}
                                </option>
                                {registrationType?.map((type) => (
                                  <option
                                    key={type.registrationTypeId}
                                    value={type.registrationTypeId}
                                  >
                                    {type.registrationType}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="businessType"
                                className="form-label"
                              >
                                Business Type
                                <sup className="text-danger">*</sup>
                              </label>
                              <select
                                // id="businessType"
                                id="merchantForm"
                                name="businessType"
                                value={formData.businessType}
                                onChange={(e) => {
                                  handleChange(e);
                                  setShowComPanInput(
                                    e.target.value === "6" ? false : true
                                  );
                                }}
                                className="form-control"
                              >
                                <option value="">
                                  Select a business type*
                                  {/* <span className="star">*</span> */}
                                </option>
                                {businessType?.map((type) => (
                                  <option
                                    key={type.companyTypeId}
                                    value={type.companyTypeId}
                                  >
                                    {type.companyType}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="businessCategory"
                                className="form-label"
                              >
                                Business Category
                                <sup className="text-danger">*</sup>
                              </label>
                              <select
                                // id="businessCategory"
                                id="merchantForm"
                                name="businessCategory"
                                value={formData.businessCategory}
                                onChange={(e) => {
                                  handleBusinessCategoryChange(e);
                                }}
                                className="form-control"
                              >
                                <option value="">
                                  Select business categories*
                                </option>
                                {businessCategories.map((category) => (
                                  <option
                                    key={category.businessTypeId}
                                    value={category.businessTypeId}
                                  >
                                    {category.businessType}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          {showOtherInput && (
                            <Col xs={12} lg={4} md={6}>
                              <div className="form-group">
                                <label
                                  htmlFor="otherBusinessCategory"
                                  className="form-label"
                                >
                                  Other Business Category
                                  <sup className="text-danger">*</sup>{" "}
                                </label>
                                <input
                                  type="text"
                                  id="merchantForm"
                                  className="form-control"
                                  name="otherBusinessCategory"
                                  value={formData.otherBusinessCategory}
                                  onChange={handleChange}
                                  // className="form-control"
                                />
                                <span
                                  id="otherBusinessErr"
                                  className=" text-danger d-none"
                                >
                                  Please Fill Business Category*
                                </span>
                              </div>
                            </Col>
                          )}
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="companyName"
                                className="form-label"
                              >
                                Company Name
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                // id="companyName"
                                id="merchantForm"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                className="form-control"
                              />

                              <span id="" className=" text-primary ">
                                *Note- The Company name must be similar to the
                                Bank Account Name
                              </span>
                            </div>
                          </Col>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="gstNumber" className="form-label">
                                GST Number
                              </label>
                              <div className="input-field-validate">
                                <input
                                  type="text"
                                  // id="gstNumber"
                                  id="merchantForm2"
                                  name="gstNumber"
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={formData.gstNumber}
                                  onChange={handleChange}
                                  className="form-control"
                                />
                                <div className="login-btn-sec">
                                  <button
                                    id="gstVerifyBtn"
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() => handleGstVerification()}
                                  >
                                    Verify GST
                                  </button>
                                </div>
                              </div>
                              <span
                                id="gstHolderName"
                                className="text-primary"
                              ></span>
                            </div>
                          </Col>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="CompanyPanCardHolderName"
                                className="form-label"
                              >
                                Name on Company Pan
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="input-field-validate">
                                <input
                                  type="text"
                                  id="CompanyPanCardHolderName"
                                  name="CompanyPanCardHolderName"
                                  onChange={handleChange}
                                  className="form-control"
                                  value={formData.CompanyPanCardHolderName}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="CompanyPanCardHolderDOB"
                                className="form-label"
                              >
                                DOB on Company Pan
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="input-field-validate">
                                <input
                                  type="text"
                                  id="CompanyPanCardHolderDOB"
                                  name="CompanyPanCardHolderDOB"
                                  onChange={handleChange}
                                  className="form-control"
                                  value={formData.CompanyPanCardHolderDOB}
                                  placeholder="DD/MM/YYYY"
                                />
                              </div>
                            </div>
                          </Col>
                          {!showComPanInput ? null : (
                            <Col xs={12} lg={4} md={6}>
                              <div className="form-group">
                                <label
                                  htmlFor="companyPanNumber"
                                  className="form-label"
                                >
                                  Company PAN Number
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="input-field-validate">
                                  <input
                                    type="text"
                                    // id="companyPanNumber"
                                    id="merchantForm2"
                                    name="companyPanNumber"
                                    onKeyDown={(e) => {
                                      if (e.key === " ") {
                                        e.preventDefault();
                                      }
                                    }}
                                    value={formData.companyPanNumber}
                                    onChange={handleChange}
                                    onBlur={handelValidations}
                                    className="form-control"
                                  />

                                  <div className="login-btn-sec">
                                    <button
                                      id="companyPanVerifyBtn"
                                      type="button"
                                      className="btn btn-success"
                                      onClick={() =>
                                        handlePanVerification("company")
                                      }
                                    >
                                      Verify PAN
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="businessAs"
                                className="form-label"
                              >
                                Business As / Doing Business as :
                              </label>
                              <input
                                type="text"
                                // id="businessAs"
                                id="merchantForm2"
                                name="businessAs"
                                value={formData.businessAs}
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                onChange={handleChange}
                                className="form-control"
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="website" className="form-label">
                                Website
                              </label>
                              <input
                                type="text"
                                // id="website"
                                id="merchantForm2"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="website"
                                value={formData.website}
                                onChange={handleChange}
                                className="form-control"
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="website" className="form-label">
                                App URL
                              </label>
                              <input
                                type="text"
                                // id="website"
                                id="merchantForm2"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="appUrl"
                                value={formData.appUrl}
                                onChange={handleChange}
                                className="form-control"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Card.Title className="h3 mt-3">
                          {" "}
                          Authorized Person Details
                        </Card.Title>
                        <Row>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="firstName" className="form-label">
                                First Name (As per Aadhaar)
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                // id="firstName"
                                id="merchantForm"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="form-control"
                              />
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="lastName" className="form-label">
                                Last Name (As per Aadhaar)
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                // id="lastName"
                                id="merchantForm"
                                name="lastName"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                value={formData.lastName}
                                onChange={handleChange}
                                className="form-control"
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="aadharNumber"
                                className="form-label"
                              >
                                Aadhaar Number
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="input-field-validate">
                                <input
                                  type="number"
                                  // id="aadharNumber"
                                  id={styles.aadharForm}
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                    setPressedKeyName(getKeyPressed(e));
                                  }}
                                  name="aadharNumber"
                                  value={formData.aadharNumber}
                                  onChange={handleChange}
                                  className="form-control"
                                />
                                <div className="login-btn-sec">
                                  <button
                                    id="adharSendOTPBtn"
                                    type="button"
                                    className="btn btn-success"
                                    // onClick={sendOTP}
                                    onClick={
                                      sendOTPCount >= 3
                                        ? handleSkipAadharVerification
                                        : sendOTP
                                    }
                                  >
                                    {sendOTPCount >= 3
                                      ? "Skip"
                                      : showResendOTP
                                      ? "Resend OTP"
                                      : "Send OTP"}
                                  </button>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between">
                                <span
                                  id="aadharCustomerName"
                                  className="text-primary"
                                ></span>
                                <span
                                  id="countdowntimer"
                                  className="text-danger"
                                >
                                  {isOtpTimerStart && otpTimer != 0
                                    ? `Resend OTP in ${otpTimer} seconds`
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </Col>
                          {showOtpInput && (
                            <Col xs={12} lg={4} md={6}>
                              <div className="form-group">
                                <label htmlFor="otp" className="form-label">
                                  OTP<sup className="text-danger">*</sup>
                                </label>
                                <div className="input-field-validate">
                                  <input
                                    type="number"
                                    // id="otp"
                                    onKeyDown={(e) => {
                                      if (e.key === " ") {
                                        e.preventDefault();
                                      }
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    id={styles.aadharForm3}
                                    name="otp"
                                    value={formData.otp}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                  {showOtpInput && (
                                    <div className="login-btn-sec">
                                      <button
                                        id="adharOTPVerifyBtn"
                                        type="button"
                                        className="btn btn-success"
                                        onClick={() => handleOTPVerification()}
                                      >
                                        Verify OTP
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          )}

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="panCardNumber"
                                className="form-label"
                              >
                                PAN Card Number (Authorized Person)
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="input-field-validate">
                                <input
                                  type="text"
                                  // id="panCardNumber"
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                  }}
                                  id={styles.aadharForm3}
                                  name="panCardNumber"
                                  onChange={handleChange}
                                  onBlur={handelValidations}
                                  className="form-control"
                                  value={formData.panCardNumber}
                                />

                                <div className="login-btn-sec">
                                  <button
                                    id="prsnlPanVerifyBtn"
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() =>
                                      handlePanVerification("personal")
                                    }
                                  >
                                    Verify PAN
                                  </button>
                                </div>
                              </div>

                              {/* <span
                                id="prsnlPanName"
                                className="text-primary"
                              ></span> */}

                              {panVerify &&
                                panVerify.message === "Verified" &&
                                panVerify.status === "Success" &&
                                showPopupPan && (
                                  <div className="popup_container">
                                    <div className="popup">
                                      <h3>Success</h3>
                                      <p>PAN Number verified successfully.</p>
                                      <p>
                                        PAN number associated with :
                                        <span className="gst_name">
                                          {panVerify.data.Name}
                                        </span>
                                      </p>
                                      <button
                                        className="close_button"
                                        onClick={() => setShowPopupPan(false)}
                                      >
                                        Okay{" "}
                                      </button>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="panCardHolderName"
                                className="form-label"
                              >
                                PAN Card Holders Name
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="input-field-validate">
                                <input
                                  type="text"
                                  id="panCardHolderName"
                                  name="panCardHolderName"
                                  onChange={handleChange}
                                  className="form-control"
                                  value={formData.panCardHolderName}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="panCardHolderDOB"
                                className="form-label"
                              >
                                PAN Holders DOB
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="input-field-validate">
                                <input
                                  type="text"
                                  id="panCardHolderDOB"
                                  name="panCardHolderDOB"
                                  onChange={handleChange}
                                  className="form-control"
                                  value={formData.panCardHolderDOB}
                                  placeholder="DD/MM/YYYY"
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xs={12} lg={12} md={12}>
                            <div className="form-group d-flex justify-content-end gap-3">
                              <div className="login-btn-sec toolbar-bt text-end">
                                <button
                                  id="saveDetailsBtn"
                                  className="submit_link_info btn btn-success"
                                  type="submit"
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </div>

                    <div className="merchant-form-info"></div>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default AdharForm;
